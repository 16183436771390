<template>
  <div>
    <!-- Desktop Navigation -->
    <v-app-bar app class="hidden-sm-and-down" height="100%">
      <v-container>
        <v-row justify="start">
          <v-col cols="12" class="text-left" lg="2" md="2">
            <v-img
              src="../assets/images/logone.png"
              height="100%"
              contain
            ></v-img>
          </v-col>
          <v-col
            cols="12"
            lg="10"
            align-self="center"
            md="10"
            class="text-right hdr"
          >
            <v-btn
              v-for="(item, index) in navItems"
              :key="index"
              :to="item.route"
              :class="setActiveButton(item.route)"
              variant="text"
              elevation="0"
              class="remove-button-highlight"
              style="background-color: white"
              @click="scrollToTop"
            >
              {{ item.label }}
            </v-btn>
            <v-menu>
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="text" 
              @click="scrollToTop"
                >
                  Products & Services
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  :value="index"
                  :to="item.route"
                >
                  <v-list-item-title class="drop">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              style="background-color: #102a4b; color: white"
              @click="navigateToOtherPage"
            >
              Connect To Procis
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Mobile Navigation -->
    <div class="hidden-md-and-up">
      <v-app-bar app class="hidden-md-and-up">
        <v-row justify="space-between">
          <v-col cols="5" class="pl-8" sm="3">
            <v-img src="../assets/images/logone.png" height="40px"></v-img>
          </v-col>
        </v-row>
        <v-app-bar-nav-icon @click="mobileMenu = !mobileMenu">
          <v-icon color="black"> mdi-menu </v-icon></v-app-bar-nav-icon
        >
      </v-app-bar>
      <v-navigation-drawer
        class="custom-navigation-drawer hdr"
        location="right"
        app
        temporary
        v-model="mobileMenu"
        style="position: fixed"
      >
        <v-list>
          <v-row class="px-8 py-5 pt-16">
            <v-col
              cols="12"
              v-for="(item, index) in navItems"
              :key="index"
              :to="item.route"
              :class="setActiveButton(item.route)"
            >
              <router-link :to="item.route" class="router-link">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </router-link>
            </v-col>
            <v-row class="px-3 py-5">
              <v-col cols="12">
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <span v-bind="props"> Projects & Services </span>
                  </template>
                  <v-list class="align-top">
                    <v-list-item
                      v-for="(item, index) in items"
                      :key="index"
                      :value="index"
                      :to="item.route"
                    >
                      <v-list-item-title class="drop">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <v-btn
                  style="background-color: #102a4b; color: white"
                  @click="navigateToOtherPage"
                >
                  Connect To Procis
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        // "Home", "About Us", "Gallery"
        { label: "Home", route: "/" },
        { label: "About Us", route: "/aboutus" },
        { label: "Gallery", route: "/gallery" },
      ],
      mobileMenu: false,
      isHighlighted: false,
      items: [
        { title: "Procis Skilent", route: "/procisskilent" },
        { title: "Procis STEM", route: "/procisstem" },
      ],
    };
  },
  computed: {
    activeRoute() {
      return this.$route.path;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    toggleHighlight() {
      this.isHighlighted = !this.isHighlighted;
    },
    setActiveButton(route) {
      return route === this.activeRoute ? "active-button" : "";
    },
    navigateToOtherPage() {
      this.$router.push("/contact");
    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style scoped>
.custom-navigation-drawer {
  margin-top: 0;
  padding-top: 0;
}
.router-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.hdr {
  font-family: opensansbld;
}
.drop {
  font-family: opensans;
  font-size: 16px;
  font-weight: 600;
}
.active-button {
  background-color: #ffffff;
  color: #005eca;
}
.remove-button-highlight {
  outline: none;
  background-color: #ffffff;
}
.highlighted {
  background-color: #ffffff; /* Change this to your desired highlight color */
  color: rgb(221, 0, 0); /* Change this to your desired text color */
}
</style>