<template>
  <v-app>
    <Header/>  
    <v-main class="px-0 py-0 mx-0" >
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Header from "./firstpage/HeaderPage.vue"
import Footer from "./firstpage/FooterPage.vue"
export default {
  name: 'App',

  components:{
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
}
</script>
