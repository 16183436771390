import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import('../firstpage/FinalPage')
    }
  },
  {
    path: '/header',
    name: 'headerpage',
    component: function () {
      return import('../firstpage/HeaderPage')
    }
  },
  {
    path: '/intro',
    name: 'intropage',
    component: function () {
      return import('../firstpage/IntroPage')
    }
  },
  {
    path: '/startup',
    name: 'startup',
    component: function () {
      return import('../firstpage/StartUp')
    }
  },
  {
    path: '/product',
    name: 'product',
    component: function () {
      return import('../firstpage/ProductsPage')
    }
  },
  {
    path: '/form',
    name: 'form',
    component: function () {
      return import('../firstpage/FormPage')
    }
  },
  {
    path: '/footer',
    name: 'footer',
    component: function () {
      return import('../firstpage/FooterPage')
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: function () {
  //     return import('../secondpage/AboutUs')
  //   }
  // },
  {
    path: '/team',
    name: 'ourteam',
    component: function () {
      return import('../secondpage/OurTeam')
    }
  },
  {
    path: '/aboutus',
    name: 'aboutuspage',
    component: function () {
      return import('../secondpage/SecondFinal')
    }
  },
  {
    path: '/gallery',
    name: 'gallerypage',
    component: function () {
      return import('../firstpage/GalleryPage')
    }
  },
  {
    path: '/procisskilent',
    name: 'skilentpage',
    component: function () {
      return import('../firstpage/SkilentPage')
    }
  },
  {
    path: '/procisstem',
    name: 'stempage',
    component: function () {
      return import('../firstpage/StemPage')
    }
  },
  {
    path: '/hello',
    name: 'helopage',
    component: function () {
      return import('../components/HelloWorld')
    }
  },
  {
    path: '/contact',
    name: 'contactuspage',
    component: function () {
      return import('../firstpage/ContactusPage')
    }
  },
]

const router = createRouter({ 
  history: createWebHistory(),
  routes
})

export default router
