<template>
  <div style="background-color: #102a4b">
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="center" class="pl-lg-3">
          <v-col cols="12" lg="2" class="pl-16-lg pt-10" md="2" sm="2">
            <v-img src="../assets/images/log1.png"></v-img>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
            <v-col class="sub" cols="12">
              <v-row justify="end">
                <v-col cols="9" class="text-left">
                  <v-btn style="color: white" variant="text">NAVIGATION</v-btn>
                </v-col>
                <v-col class="subtwo text-left" cols="9"
                  ><v-btn variant="text" @click="home()">Home</v-btn></v-col
                >
                <v-col class="subtwo text-left" cols="9"
                  ><v-btn variant="text" @click="gallery()"
                    >Gallery</v-btn
                  ></v-col
                >
                <v-col class="subtwo text-left" cols="9"
                  ><v-btn variant="text" @click="product()"
                    >Product & Services</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
            <v-col class="sub" cols="12">
              <v-row justify="end">
                <v-col cols="9" class="text-left">
                  <v-btn style="color: white" variant="text" @click="about()">ABOUT</v-btn>
                </v-col>
                <v-col class="subtwo text-left" cols="9"
                  ><v-btn variant="text" @click="contact()">Contact Us</v-btn></v-col
                >
                <v-col class="subtwo text-left" cols="9"
                  ><v-btn variant="text"
                    >Privacy Policy</v-btn
                  ></v-col
                >
                <v-col class="subtwo text-left pr-3" cols="9"
                  ><v-btn variant="text"
                    >Terms & Conditions</v-btn
                  ></v-col
                >
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="3" class="pl-16-lg pt-10">
            <v-col class="sub" cols="12">
              <v-row justify="end">
                <v-col cols="9" class="text-left">
                  <v-btn style="color: white" variant="text">FOLLOW US</v-btn>
                </v-col>
                <v-col class="subtwo" cols="9"
              ><a
                href="https://www.linkedin.com/company/procis-brainlabs/about/"
                ><v-btn variant="text" class="custom-button">LinkedIn</v-btn></a
              ></v-col
            >
            <v-col class="subtwo" cols="9"
              ><a
                href="https://www.facebook.com/groups/839276144578272/?ref=share&mibextid=NSMWBT"
                ><v-btn variant="text" class="custom-button">Facebook</v-btn></a
              ></v-col
            >
            <v-col class="subtwo" cols="9"
              ><a
                href="https://www.instagram.com/invites/contact/?i=90vbwyyp8eoe&utm_content=n9t0rc5"
                ><v-btn variant="text" class="custom-button"
                  >Instagram</v-btn
                ></a
              ></v-col
            >
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="ftr" style="background-color: #0f57b2">
      <v-col class="text-center" cols="11" lg="7" align-self="center">
        <span style="color: white"
          >© Procisbrainlabs 2023 - All rights reserved  |  Designed & Developed
          by <span style="color: #f4c671">Leopard Tech Labs Pvt Ltd</span></span
        >
      </v-col>
    </v-row>
  </div>
  <div></div>
</template>
  <style scoped>
.sub {
  color: #ffffff;
  font-size: 18px;
}

.subtwo {
  color: #cfc7c7;
  font-size: 16px;
  line-height: 21.79px;
  font-family: opensans;
}
.ftr {
  font-size: 15px;
  font-weight: 400;
  font-family: opensans;
}
.v-row {
  margin: 0px;
}
.custom-link {
  text-decoration: none;
  color: #cfc7c7;
}

.custom-link:hover {
  text-decoration: none;
}

.custom-button {
  background: transparent;
  color: #cfc7c7;
}
</style>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    home() {
      this.$router.push("/");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    gallery() {
      this.$router.push("/gallery");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    product() {
      this.$router.push("/procisstem");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    contact() {
      this.$router.push("/contact");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    about() {
      this.$router.push("/aboutus");
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
  
};
</script>