import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

import axios from 'axios';
import './assets/style/font.css';
import './assets/style/style.css';
import AOS from 'aos'
import 'aos/dist/aos.css'

loadFonts()

const app = createApp(App);
AOS.init({
  offset: 100, // Set the offset (in pixels) from the top of the window for triggering animations
  duration: 1000, // Animation duration (in milliseconds)
  easing: 'ease-in-out', // Easing function for the animation
  once: false, // Allow animations to trigger each time the element enters the viewport
});
app.use(AOS);

// axios.defaults.baseURL = 'https:............/';
// app.config.globalProperties.baseURL = "https:............/"
// app.config.globalProperties.mediaURL = "https:............/file/get/"

app.config.globalProperties.$axios = axios;

app
  // .use(AOS)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');